import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["template"];

  addScheduleItem(event) {
    event.preventDefault();

    let newForm = this.templateTarget.cloneNode(true);
    newForm.dataset.programBuilderScheduleTarget = '';

    const scheduleSection = event.target.closest('.js-editor-schedule-section');
    const items = scheduleSection.querySelectorAll('.js-editor-schedule-item');

    if (items.length) {
      const lastItem = items[items.length - 1];

      lastItem.after(newForm);

      this._updateItemCounter(lastItem, newForm, true);
    } else {
      scheduleSection.append(newForm);
    }

    newForm.classList.remove("hidden");
    newForm.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "center"});
  }

  removeScheduleItem(event) {
    event.preventDefault();

    let scheduleItem = event.target.closest(".js-editor-schedule-item");

    this._updateItemCounters(scheduleItem);

    scheduleItem.remove();
  }

  _updateItemCounters(scheduleItem) {
    let nextSibling = scheduleItem.nextElementSibling;

    while (nextSibling) {
      this._updateItemCounter(nextSibling, null, false);

      nextSibling = nextSibling.nextElementSibling;
    }
  }

  _updateItemCounter(baseCounterElement, updateCounterElement, increase) {
    let counterElement = baseCounterElement.querySelector('.js-editor-schedule-item-counter');

    let counterStrings = counterElement.innerHTML.trim().split(/\W+/);

    if (updateCounterElement) {
      let newFormCounter = updateCounterElement.querySelector('.js-editor-schedule-item-counter');

      this._updateCounterString(newFormCounter, counterStrings);
      this._updateFormNames(updateCounterElement, counterStrings, increase);
    } else {
      this._updateCounterString(counterElement, counterStrings, increase);
      this._updateFormNames(baseCounterElement, counterStrings, increase);
    }
  }

  _updateCounterString(element, counterStrings, increase = true) {
    let startValue = parseInt(counterStrings[1]) || 0;

    if (increase) {
      element.innerHTML = `${counterStrings[0]} ${startValue + 1}`;
    } else {
      element.innerHTML = `${counterStrings[0]} ${startValue - 1}`;
    }
  }

  _updateFormNames(element, counterStrings, increase = true) {
    let startValue = parseInt(counterStrings[1]) || 0;

    let counter = increase ? (startValue + 1) : (startValue - 1);

    var fields = element.querySelectorAll('[name]');

    fields.forEach(field => {
      if (field.name.includes('_index_')) {
        field.name = field.name.replace('_index_', counter);
        field.id = field.id.replace('_index_', counter);
      } else {
        field.name = field.name.replace(/\d+/, counter);
        field.id = field.id.replace(/\d+/, counter);
      }
    });
  }
}

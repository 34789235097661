import { Controller } from "stimulus";

import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form"]

  submit(event) {
    event.preventDefault();

    this.successMessageElement = document
      .getElementById('toast-message-container-admin');

    this.formWithErrors = false;

    this.submitForms();
  }

  async submitForms() {
    const promises = this.formTargets.map(form => {
      return new Promise((resolve, reject) => {
        const formData = new FormData(form);

        const action = form.action;
        const method = form.method;

        ajax({
          url: action,
          type: method,
          data: formData,
          dataType: "script",
          beforeSend: (xhr) => {
            this.ajaxRequest = xhr;
            return true;
          },
          success: () => {
            resolve();
          },
          error: (xhr, status, error) => {
            this.formWithErrors = true;
            resolve();
          }
        });
      });
    });

    try {
      await Promise.all(promises);

      if (this.formWithErrors) {
        this._displayErrorMessage();
      } else {
        this._displaySuccessMessage();
      }
    } catch (error) {
      this._displayErrorMessage();
    }
  }

  _displayErrorMessage() {
    this.successMessageElement.innerHTML = '';
    this.successMessageElement.classList.remove('hidden');

    let formResponseController = this.application.controllers.find(controller => {
      return controller.context.identifier === 'form-response';
    });

    let message = this.formTarget.dataset.formSubmitErrorMessage;

    formResponseController.showCustomError(message);
  }

  _displaySuccessMessage() {
    this.successMessageElement.classList.remove('hidden');
  }
}

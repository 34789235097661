import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    key: String,
    permanent: Boolean
  }
  connect() {
    if (this.keyValue !== "error" && this.permanentValue == false) {
      window.setTimeout(() => {
        this.element.remove();
      }, 10000);
    }
  }

  remove(event) {
    this.element.remove();
  }
}
